import './eventLayer';
/* global $ */

window.app = window.app || {};

(function (eventLayer) {
  const URL = window.location.origin + '/internal_services/google_tag_manager';

  function GoogleTagManager() {}

  GoogleTagManager.prototype.loggedUserEvent = function (companyId) {
    if (!companyId) {
      return;
    }

    const exec = this.executeEvent;
    $.ajax({
      url: URL + '/logged/' + companyId,
      method: 'GET',
      success: function (data) {
        if (!data.user_name) return;
        exec('logged_user', data);
      },
      error: function () {},
    });
  };

  GoogleTagManager.prototype.executeEvent = function (eventName, data) {
    eventLayer.send(eventName, data || {});
  };

  window.app.GoogleTagManager = GoogleTagManager;
}(window.EventLayer));
