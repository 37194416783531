import Swiper from 'swiper/bundle';
import AOS from 'aos';

import './_home';
import './_contas_a_receber';
import './_form';
import './_simulation';
import './_quem_somos';
import '../../shared/googleTagManager';

// require static_pages/_quem_somos
/* global $ */

$(document).ready(function () {
  // todos
  AOS.init({
    disable: 'mobile',
    duration: 1000,
    once: true,
  });

  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 25) {
      $('header').addClass('fixed-menu');
    } else {
      $('header').removeClass('fixed-menu');
    }
  });

  $('.js-open-dropdown-user').on('click', function () {
    $('.js-dropdown-user').toggleClass('show-drop');
  });

  $('#js-open-menu').on('click', function (evt) {
    evt.preventDefault();
    $(document.documentElement).toggleClass('menu-opened');
  });

  // contas_a_receber, antecipacao, landing pages
  $('.js-pergunta').on('click', function () {
    $(this).parent().toggleClass('active');
  });

  // landing pages
  $('body').on('click', '.js-btn-goto', function (evt) {
    evt.preventDefault();
    $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top }, 500);
  });

  // home, quem_somos, landing pages
  (function (Sw) {
    return new Sw('.slide-depoimentos', {
      slidesPerView: 4,
      spaceBetween: 32,
      speed: 800,
      pagination: {
        el: '.slide-depoimentos .ctrl-slide .swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.slide-depoimentos .btn-next',
        prevEl: '.slide-depoimentos .btn-prev',
      },
      breakpoints: {
        320: { slidesPerView: 1.3, spaceBetween: 12 },
        768: { slidesPerView: 2, spaceBetween: 20 },
        1200: { slidesPerView: 4, spaceBetween: 32 },
      },
    });
  }(Swiper));

  // antecipacao, landing pages
  (function (Sw) {
    return new Sw('.slide-funcional', {
      spaceBetween: 10,
      speed: 800,
      autoHeight: true,
      pagination: {
        el: '.slide-funcional .swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
      },
      navigation: {
        nextEl: '.slide-funcional .btn-next',
        prevEl: '.slide-funcional .btn-prev',
      },
    });
  }(Swiper));

  (function () {
    const googleTagManager = new window.app.GoogleTagManager();
    googleTagManager.loggedUserEvent($('body').data('logged-company-id'));
  }());
});
