/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable one-var-declaration-per-line */
/* eslint-disable one-var */
import $ from 'jquery';
import 'jquery-validation/dist/jquery.validate.min';
import { moneyStringToFloat, roundValue } from '../shared/helpers';

$.validator.addMethod('phoneBR', function (phoneNumber, element) {
  const number = phoneNumber.replace(/\s+/g, '').replace(/\D/g, '');
  const numberWithoutDDD = number.slice(2, number.length);
  const sequentialNumbers = [...Array(10).keys()].map((value) => value.toString().repeat(numberWithoutDDD.length));

  if (sequentialNumbers.includes(numberWithoutDDD)) {
    return (this.optional(element) || false);
  }
  if (['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10'].indexOf(phoneNumber.substring(0, 2)) !== -1) {
    return (this.optional(element) || false);
  }
  if (!(number.length === 10 || number.length === 11)) {
    return (this.optional(element) || false);
  }

  return (this.optional(element) || true);
}, 'Por favor, forne&ccedil;a um n&uacute;mero de telefone v&aacute;lido.');

$.validator.addMethod('mobileBR', function (mobileNumber, element) {
  const number = mobileNumber.replace(/[^0-9]/g, '');

  if (number === '00000000000') {
    return (this.optional(element) || false);
  }
  if (['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10'].indexOf(mobileNumber.substring(0, 2)) !== -1) {
    return (this.optional(element) || false);
  }
  if (number.length !== 11) {
    return (this.optional(element) || false);
  }
  if (number[2] !== '9') {
    return (this.optional(element) || false);
  }
  return (this.optional(element) || true);
}, 'Por favor, forne&ccedil;a um n&uacute;mero de celular v&aacute;lido.');

$.validator.addMethod('cnpjBR', function (value, element) {
  return this.optional(element) || validarCNPJ(value);
}, 'Por favor, forne&ccedil;a um n&uacute;mero de CNPJ v&aacute;lido.');

/*
 * Brazillian CPF number (Cadastrado de Pessoas Físicas) is the equivalent of a Brazilian tax registration number.
 * CPF numbers have 11 digits in total: 9 numbers followed by 2 check numbers that are being used for validation.
 */
$.validator.addMethod('cpfBR', function (value, element) {
  return this.optional(element) || validarCPF(value);
}, 'Por favor, fornece&ccedil;a um n&uacute;mero de CPF v&aacute;lido.');

$.validator.addMethod('cnpjCpfBr', function (value, element) {
  return this.optional(element) || cnpjCpfValid(value);
}, 'Por favor, forne&ccedil;a um n&uacute;mero v&aacute;lido.');

$.validator.addMethod('fullName', function (value, element) {
  return this.optional(element) || fullName(value);
}, 'Por favor, insira seu nome completo');

$.validator.addMethod('greaterThanZero', function (value, _element) {
  if (value > 0) return true;

  return typeof value === 'string' && parseFloat(value.replace('.', '', ',', '.')) > 0;
}, $.validator.format('O valor deve ser maior do que zero'));

$.validator.addMethod('fineRateToCompany', function (value, _element) {
  return verifyLimitInterest(value, 0.2);
}, 'Multa por atraso não pode ser maior que 20% para Pessoa jurídica!');

$.validator.addMethod('fineRateToUser', function (value, _element) {
  return verifyLimitInterest(value, 0.02);
}, 'Multa por atraso não pode ser maior que 2% para Pessoa física!');

$.validator.addMethod('monthlyInterestRateToCompany', function (value, _element) {
  return verifyLimitInterest(value, 0.2);
}, 'Juros de mora não pode ser maior que 20% para Pessoa jurídica');

$.validator.addMethod('monthlyInterestRateToUser', function (value, _element) {
  return verifyLimitInterest(value, 0.01);
}, 'Juros de mora não pode ser maior que 1% para Pessoa física');

$.validator.addMethod('installmentsValueSum', function (value, element, totalValue) {
  let totalValueFromInstallments = 0;
  const elements = $(`.${$(element).attr('data-validation-class')}`);

  elements.each(function () {
    totalValueFromInstallments += moneyStringToFloat(this.value);
  });

  if (roundValue(totalValueFromInstallments) <= totalValue) {
    return true;
  }

  return false;
}, 'A soma dos valores das parcelas ultrapassa o valor total da cobrança');

function fullName(value) {
  return value.trim().split(' ').length > 1;
}

function cnpjCpfValid(value) {
  const cnpjCpfValue = value.replace(/([~!@#$%^&*()_+=`{}[\]\-|\\:;'<>,./? ])+/g, '');

  return cnpjCpfValue.length <= 11 ? validarCPF(cnpjCpfValue) : validarCNPJ(cnpjCpfValue);
}

/*
 * Brazillian CPF number (Cadastrado de Pessoas Físicas) is the equivalent of a Brazilian tax registration number.
 * CPF numbers have 11 digits in total: 9 numbers followed by 2 check numbers that are being used for validation.
 */
function validarCPF(value) {
  // Removing special characters from value
  const newValue = value.replace(/([~!@#$%^&*()_+=`{}[\]\-|\\:;'<>,./? ])+/g, '');

  // Checking value to have 11 digits only
  if (newValue.length !== 11) {
    return false;
  }

  // eslint-disable-next-line vars-on-top
  var sum = 0,
    firstCN, secondCN, checkResult, i;

  firstCN = parseInt(value.substring(9, 10), 10);
  secondCN = parseInt(value.substring(10, 11), 10);

  checkResult = function (s, cn) {
    let result = (s * 10) % 11;
    if ((result === 10) || (result === 11)) {
      result = 0;
    }
    return (result === cn);
  };

  // Checking for dump data
  if (value === ''
    || value === '00000000000'
    || value === '11111111111'
    || value === '22222222222'
    || value === '33333333333'
    || value === '44444444444'
    || value === '55555555555'
    || value === '66666666666'
    || value === '77777777777'
    || value === '88888888888'
    || value === '99999999999'
  ) {
    return false;
  }

  // Step 1 - using first Check Number:
  for (i = 1; i <= 9; i++) {
    // eslint-disable-next-line operator-assignment
    sum = sum + parseInt(value.substring(i - 1, i), 10) * (11 - i);
  }

  // If first Check Number (CN) is valid, move to Step 2 - using second Check Number:
  if (checkResult(sum, firstCN)) {
    sum = 0;
    for (i = 1; i <= 10; i++) {
      // eslint-disable-next-line operator-assignment
      sum = sum + parseInt(value.substring(i - 1, i), 10) * (12 - i);
    }
    return checkResult(sum, secondCN);
  }
  return false;
}

function validarCNPJ(cnpj) {
  // eslint-disable-next-line no-param-reassign
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;
  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj === '00000000000000'
     || cnpj === '11111111111111'
     || cnpj === '22222222222222'
     || cnpj === '33333333333333'
     || cnpj === '44444444444444'
     || cnpj === '55555555555555'
     || cnpj === '66666666666666'
     || cnpj === '77777777777777'
     || cnpj === '88888888888888'
     || cnpj === '99999999999999') return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  let i;

  for (i = tamanho; i >= 1; i--) {
    // eslint-disable-next-line no-plusplus
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  // eslint-disable-next-line no-mixed-operators
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0)) return false;

  // eslint-disable-next-line operator-assignment
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (i = tamanho; i >= 1; i--) {
    // eslint-disable-next-line no-plusplus
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  // eslint-disable-next-line no-mixed-operators
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

function verifyLimitInterest(value, limitNumber) {
  if (value === undefined || value === '') return true;

  const result = parseFloat(value) / 100.0;

  return result <= limitNumber;
}
