/* global $ */

import '../../components/forms/animatedLabelInputs';
import '../../components/forms/formValidation';
import '../../components/forms/maskConfig';
import '../../components/forms/showPasswordInputs';

$(document).ready(function () {
  new window.app.AnimatedLabelInputs().bindEvents();
  new window.app.MaskConfig().bindMasks();
  new window.app.ShowPasswordInputs().bindEvents();

  $('form').each(function () {
    const selector = '#' + this.id;
    new window.app.FormValidation({ formSelector: selector, emailValidation: true }).bindValidation();
  });

  $('.js-checkbox').on('click', function () {
    const check = $(this);
    const input = check.parent().find('input[type="hidden"]');

    check.toggleClass('active')
      .parents('form')
      .find('[type="submit"]')
      .prop('disabled', !check.hasClass('active'));

    input.val(check.hasClass('active') ? 1 : 0);
  });
});
