/* global $ */

(function (dataLayer) {
  function send(event, values = {}) {
    const data = $.extend({ event: event }, values);
    dataLayer.push(data);
  }

  window.EventLayer = {
    send: send,
  };
}(window.dataLayer || []));
