import $ from 'jquery';
import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import 'jquery-validation/dist/localization/messages_pt_BR.min';
import '../../utils/validate.additionalMethods';

window.app = window.app || {};

(function () {
  const DEFAULT_FORM_SELECTOR = '.with-validation';
  const DEFAULT_EMAIL_SELECTOR = '.with-custom-email-validation';
  const DEFAULT_EMAIL_MESSAGE = 'Por favor, forneça um endereço de email válido';

  function FormValidation(params) {
    const config = params || {};
    this.form = $(config.formSelector || DEFAULT_FORM_SELECTOR);
    this.emailInputSelector = config.emailInputSelector || DEFAULT_EMAIL_SELECTOR;
    this.emailValidation = config.emailValidation || false;
    this.rules = config.rules || {};
  }

  const fn = FormValidation.prototype;

  fn.bindValidation = function () {
    const validationRules = this.rules;
    if (this.emailValidation) {
      $.extend(true, validationRules, rulesForEmailValidation(this.form, this.emailInputSelector));
    }

    this.form.validate(validationRules);
  };

  function rulesForEmailValidation(form, selector) {
    const rules = {};
    const messages = {};

    form.find(selector).each(function () {
      const el = $(this);
      const name = el.attr('name');

      messages[name] = el.data('email-validation-message') || DEFAULT_EMAIL_MESSAGE;
      rules[name] = emailInputRuleObject(el);
    });

    return {
      onkeyup: false,
      messages: messages,
      rules: rules,
    };
  }

  function emailInputRuleObject(jqueryElement) {
    return {
      required: jqueryElement.prop('required'),
      remote: {
        url: jqueryElement.data('email-validation-url'),
        type: 'get',
        data: {
          email: function () { return jqueryElement.val(); },
        },
        beforeSend: function () { jqueryElement.addClass('input-with-loading-icon').prop('disabled', true); },
        complete: function () { jqueryElement.removeClass('input-with-loading-icon').prop('disabled', false); },
      },
    };
  }

  window.app.FormValidation = FormValidation;
}());
