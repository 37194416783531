import 'inputmask';

/* global $, Inputmask */

window.app = window.app || {};

(function () {
  const DEFAULT_SELECTORS = {
    cnpj: '[inputmask="cnpj"]',
    cpf: '[inputmask="cpf"]',
    money: '[inputmask="money"]',
    phone: '[inputmask="phone"]',
    zipcode: '[inputmask="zipcode"]',
    cnpjCpf: '[inputmask="cnpj_cpf"]',
    date: '[inputmask="date"]',
    percentage: '[inputmask="percentage"]',
  };
  const CNPJ_MASK = { mask: '99.999.999/9999-99' };
  const CPF_MASK = { mask: '999.999.999-99', reverse: false };
  const LANDLINE_MASK = { mask: '(99) 9999-9999' };
  const CELLPHONE_MASK = { mask: '(99) 99999-9999' };

  function MaskConfig(params) {
    const config = params || {};

    this.cnpjFields = $(config.cnpjSelector || DEFAULT_SELECTORS.cnpj);
    this.cpfFields = $(config.cpfSelector || DEFAULT_SELECTORS.cpf);
    this.moneyFields = $(config.moneySelector || DEFAULT_SELECTORS.money);
    this.phoneFields = $(config.phoneSelector || DEFAULT_SELECTORS.phone);
    this.zipcodeFields = $(config.zipcodeSelector || DEFAULT_SELECTORS.zipcode);
    this.cnpjCpfFields = $(config.cnpjCpfSelector || DEFAULT_SELECTORS.cnpjCpf);
    this.dateFields = $(config.dateSelector || DEFAULT_SELECTORS.date);
    this.percentageFields = $(config.percentageSelector || DEFAULT_SELECTORS.percentage);
  }

  const fn = MaskConfig.prototype;

  fn.bindMasks = function () {
    this.bindCnpjMask();
    this.bindCpfMask();
    this.bindMoneyMask();
    this.bindPhoneMask();
    this.bindZipcodeMask();
    this.bindCnpjCpfMask();
    this.bindDateMask();
    this.bindPercentageMask();
  };

  fn.bindCnpjMask = function (options) {
    loadInputsMask(
      this.cnpjFields,
      { ...CNPJ_MASK, ...options },
    );
  };

  fn.bindCpfMask = function (options = {}) {
    loadInputsMask(
      this.cpfFields,
      { ...CPF_MASK, ...options },
    );
  };

  fn.bindMoneyMask = function (options = {}) {
    if (this.moneyFields.length === 0) return;
    loadInputsMask(this.moneyFields, {
      alias: 'currency',
      radixPoint: ',',
      groupSeparator: '.',
      placeholder: '0,00',
      numericInput: true,
      autoGroup: true,
      rightAlign: false,
      ...options,
    });
  };

  fn.bindPhoneMask = function (options = {}) {
    loadInputsMask(
      this.phoneFields,
      { mask: [LANDLINE_MASK.mask, CELLPHONE_MASK.mask], keepStatic: true, ...options },
    );
  };

  fn.bindZipcodeMask = function () {
    loadInputsMask(
      this.zipcodeFields,
      { mask: '99999-999' },
    );
  };

  fn.bindCnpjCpfMask = function (options = {}) {
    loadInputsMask(
      this.cnpjCpfFields,
      { mask: [CPF_MASK.mask, CNPJ_MASK.mask], keepStatic: true, ...options },
    );
  };

  fn.bindDateMask = function () {
    loadInputsMask(
      this.dateFields,
      { mask: '99/99/9999' },
    );
  };

  fn.bindPercentageMask = function () {
    loadInputsMask(this.percentageFields, { alias: 'percentage' });
  };

  function loadInputsMask(elements, options = null) {
    Array.from(elements).map((element) => Inputmask(options).mask(element));
  }

  window.app.MaskConfig = MaskConfig;
}());
