/* global $ */

import { create } from 'nouislider';

$(document).ready(function () {
  if ($('#slider').length === 0) return;

  const slider = document.getElementById('slider');
  create(slider, {
    connect: 'lower',
    start: 15000,
    step: 100,
    range: {
      min: 0,
      max: 50000,
    },
  });

  slider.noUiSlider.on('update', function () {
    calculateValorAntecipado(slider.noUiSlider.get());
  });

  $('.js-btn-prazo').on('click', function () {
    $('.js-btn-prazo').removeClass('active');
    $(this).addClass('active');
    $('#dias-prazo').text(this.textContent);

    calculateValorAntecipado(slider.noUiSlider.get());
  });

  calculateValorAntecipado(slider.noUiSlider.get());

  function calculateValorAntecipado(valor) {
    const dias = Number($('#dias-prazo').text());
    const taxa = 4 / 100;
    const result = numberToReal(valor - ((taxa / 30) * dias) * valor);

    $('#amount').val(numberToReal(valor));
    $('#resultado-valor-antecipado').text('R$ ' + result);
  }

  function numberToReal(number) {
    const result = Number(number).toFixed(2).split('.');
    result[0] = result[0].split(/(?=(?:...)*$)/).join('.');

    return result.join(',');
  }
});
