/* eslint-disable no-param-reassign */
/* eslint-disable one-var-declaration-per-line */
/* eslint-disable no-var */
/* global $ */

import Image1 from '01.png';
import Image2 from '02.png';
import Image3 from '03.png';
import Image4 from '04.png';
import Image5 from '05.png';
import Image6 from '06.png';
import Image7 from '07.png';
import Image8 from '08.png';
import Image9 from '09.png';

$(document).ready(function () {
  const shuffledList = shuffle([
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
  ]);

  $('.js-btn-cultura').on('click', function (event) {
    event.preventDefault();

    $('.js-btn-cultura')
      .removeClass('active')
      .filter(this)
      .addClass('active');

    $('.s-cultura .content-culturas .tab-cultura')
      .removeClass('active')
      .filter('[data-info="' + $(this).data('info') + '"]')
      .addClass('active');
  });

  $('.s-time .geral .item img').each(function (index) {
    $(this).attr('src', shuffledList[index]);
  });

  function shuffle(array) {
    // eslint-disable-next-line one-var
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
});
