/* global $ */

$(document).ready(function () {
  $('.s-functionalities ul li a').click(function (event) {
    event.preventDefault();

    $('.s-functionalities ul li').removeClass('active');
    $(this).parent().addClass('active');

    $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top - 100 }, 1000);
  });
});
