/* global $ */

window.app = window.app || {};

(function () {
  const DEFAULT_SELECTOR = '.with-show-password';

  function ShowPasswordInputs() {}

  const fn = ShowPasswordInputs.prototype;

  fn.bindEvents = function (customSelector) {
    const selector = customSelector || DEFAULT_SELECTOR;
    const link = $(selector).find('a');

    link.click(function (evt) {
      evt.preventDefault();
      const target = $(this);
      const input = target.parent().find('input');

      if (input.attr('type') === 'password') {
        input.attr('type', 'text');
        target.text(target.data('text-label') || 'Ocultar');
      } else {
        input.attr('type', 'password');
        target.text(target.data('password-label') || 'Mostrar');
      }
    });
  };

  window.app.ShowPasswordInputs = ShowPasswordInputs;
}());
