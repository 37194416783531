/* global $ */

window.app = window.app || {};

(function () {
  const DEFAULT_SELECTOR = '.with-animated-label';

  function AnimatedLabelInputs(params) {
    const config = params || {};
    this.autofocus = config.autofocus || false;
  }

  const fn = AnimatedLabelInputs.prototype;

  fn.bindEvents = function (customSelector) {
    const selector = customSelector || DEFAULT_SELECTOR;
    const input = $(selector).find('input');

    input.focusin(function () {
      $(this).parent().addClass('input-with-animated-label-focus');
    });

    input.focusout(function () {
      const target = $(this);
      if (target.val() === '' || target.val() === '0,00') {
        target.parent().removeClass('input-with-animated-label-focus');
      }
    });

    if (this.autofocus) {
      $(selector + ':first-of-type').find('input').focus();
    }
  };

  window.app.AnimatedLabelInputs = AnimatedLabelInputs;
}());
