import Swiper from 'swiper/bundle';

/* global $ */

$(document).ready(function () {
  const navegacaoVantagens = new Swiper('.navegacao-vantagens', {
    slidesPerView: 4,
    spaceBetween: 32,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      320: { slidesPerView: 2.3, spaceBetween: 15 },
      768: { slidesPerView: 3, spaceBetween: 15 },
      1200: { slidesPerView: 4, spaceBetween: 32 },
    },
  });

  (function (Sw) {
    return new Sw('.slide-vantagens', {
      spaceBetween: 20,
      speed: 700,
      thumbs: { swiper: navegacaoVantagens },
      autoHeight: true,
    });
  }(Swiper));

  $('.js-box').on('click', function () {
    $('.js-box')
      .removeClass('active')
      .filter(this)
      .addClass('active');

    $('.s-custos .content-boxes .item')
      .removeClass('active')
      .filter('[data-info="' + $(this).data('info') + '"]')
      .addClass('active');
  });
});
