import moment from 'moment';

export const addAndRemoveClass = (element, currentClass, newClass) => (
  element.removeClass(currentClass).addClass(newClass)
);

export const moneyStringToFloat = (valueString) => (
  parseFloat(valueString.replaceAll('.', '').replace(',', '.'))
);

export const nextLetter = (currentLetter) => (
  String.fromCharCode(currentLetter.trim().charCodeAt(0) + 1)
);

export const roundValue = (value) => (
  Math.round(value * 100) / 100
);

export const serializeFormData = (formData) => (
  formData.reduce((arr, input) => (
    {
      ...arr,
      ...(input.name !== '') ? { [input.name]: input.value } : {},
    }
  ), {})
);

export const stringToMomentDate = (dateString) => (
  moment(dateString.split('/').reverse().join('-'))
);
